import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { PiMapPinLine } from "react-icons/pi";
import { SlEnvolopeLetter } from "react-icons/sl";
import RanasSlottImage from '../assets/props/reception-dinner.jpg';
import Typography from '../styles/Typography';

const DetailSection = styled.section`
  width: 100%;
  max-width: 600px;
  padding: 1rem 1.5rem;
  position: relative;
  overflow-x: hidden;
  margin: 0 auto;
`;

const SectionImage = styled.img`
  width: calc(100% + 3rem);
  max-height: 400px;
  object-fit: cover;
  object-position: 50% 40%;
  padding: 1rem 0;
  margin: 0 -1.5rem;
  display: block;
`;

const DetailsList = styled.div`
  margin: 1rem 0;
  color: #a18e6c;
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 1rem 0;
`;

const DetailName = styled.div`
  font-family: 'Cormorant SC', serif;
  font-size: 0.9rem;
  font-weight: bold;
  text-align: right;
  padding-right: 1rem;
`;

const DetailDescription = styled.div`
  font-family: "Cormorant Garamond", serif;
  font-size: 0.9rem;
  font-weight: 400;
  text-align: left;
  padding-top: 0rem;
`;

const ExternalLinksText = styled.a`
  font-family: 'Cormorant Garamond', serif;
  font-size: ${({ fontSize }) => fontSize || '0.9rem'};
  font-weight: normal;
  color: #a18e6c;
  margin: 0.5rem 0;
  display: inline-flex;
  align-items: center;
  padding: 0.3rem 0.7rem;
  border: 1px solid;
  border-radius: 5px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f3f0ea;
  }
`;

const MapPinIcon = styled(PiMapPinLine)`
  margin-right: 6px;
  margin-bottom: 0;
  font-size: 1rem;
`;

const EnvelopeIcon = styled(SlEnvolopeLetter)`
  margin-right: 9px;
  margin-bottom: 0px;
  font-size: 0.8rem;
`;

const Detail = () => {
  const { t } = useTranslation();

  const renderDetail = (detail) => {
    switch (detail.key) {
      case 'Travel':
        return (
          <DetailDescription>
            {detail.description}
            <br />
            <ExternalLinksText
              href={t('invitation.mapURL')} 
              target="_blank" 
              rel="noopener noreferrer"
              aria-label={t('invitation.mapDirection')}
            >
              <MapPinIcon />
              {t('invitation.mapDirection')}
            </ExternalLinksText>
          </DetailDescription>
        );
      case 'RSVP':
        return (
          <DetailDescription>
            {detail.description}
            <br />
            <ExternalLinksText
              style={{ backgroundColor: '#f3f0ea' }}
              // href={t('invitation.rsvpURL')}
              target="_blank" 
              rel="noopener noreferrer"
              aria-label={t('invitation.rsvp')}
            >
              <EnvelopeIcon />
              {t('invitation.rsvp')}
            </ExternalLinksText>
          </DetailDescription>
        );
      default:
        return <DetailDescription>{detail.description}</DetailDescription>;
    }
  };

  return (
    <DetailSection id="detail">
      <SectionImage src={RanasSlottImage} alt={t('invitation.locationName')} />
      <Typography $variant="cormorant-sc" fontSize="2.2rem">
        {t('detail.title')}
      </Typography>
      <DetailsList>
        {t('detail.allDetails', { returnObjects: true }).map((detail, index) => (
          <React.Fragment key={index}>
            <DetailName>{detail.name}:</DetailName>
            {renderDetail(detail)}
          </React.Fragment>
        ))}
      </DetailsList>
    </DetailSection>
  );
};

export default Detail;
